<template>
  <div class="mission">
    <el-container style="padding: 1px;">
      <el-aside width="300px">
        <OrgTree @refreshDataList="getList"></OrgTree>
      </el-aside>
      <el-main>
        <div class="search-list">
          <el-button type="primary" class="fl mb20" @click="add">添加</el-button>
        </div>
        <div class="table">
          <el-table
            :data="dataList"
            style="width: 100%"
            v-loading="dataListLoading"
            @selection-change="selectionChangeHandle"
          >
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="workName" label="宣教名称"></el-table-column>
            <el-table-column prop="creator" label="创建人"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column label="操作" header-align="right" align="right" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="goToInfo(scope.row)">详情</el-button>
                <el-button type="text" size="small" @click="goToEdit(scope.row)">修改</el-button>
                <el-button type="text" size="small" @click="del(scope.row)" style="color:#f56c6c">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import OrgTree from "./tree/tree";
export default {
  name: "mission",
  components: {
    OrgTree,
  },
  data() {
    return {
      workName: "",
      workType: "",
      createBy: "",
      dateTime: [],
      userList: [],
      typeList: [
        { name: "写死选项1", value: 1 },
        { name: "写死选项2", value: 2 },
      ],
      dataList: [],
      diseasesList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
    };
  },
  async mounted() {
    // await this.getTypeList()
    await this.getUserList();
    await this.getDiseasesList();
    await this.getList();
  },
  methods: {
    getParentNode(n, arr) {
      // console.log('getParentNode','n',n,arr,'arr');
      if (n.parent && n.parent.data.name) {
        // console.log(n.parent.data.name,'n.parent.data.name',arr,'arr');
        arr.unshift(n.parent.data.value);
        this.getParentNode(n.parent, arr);
      } else {
        return arr;
      }
    },
    async getList(d, n) {
      console.log(d, "v", n);
      let workType = "";
      let workTypeSec = "";
      let workTypeThree = "";
      let workTypeFour = "";
      let workTypeFive = "";
      let arr = ["", "", "", "", ""];
      if (d) {
        if (d.type == "宣教分类") {
          workType = d.value;
          workTypeSec = "";
          arr = [undefined, d.value, ""];
        } else {
          // type=n.parent.data.value
          // typeSec=d.value
          // if(n.level==6){

          // }
          arr = [n.data.value];
          this.getParentNode(n, arr);
        }
      }
      const user = JSON.parse(localStorage.getItem("userInfo"));
      console.log(user.deptPid, "user");
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/missionaryWork/list"),
        method: "post",
        data: {
          stringParam1: this.workName,
   
		  stringParam2: arr[1],
		  stringParam3: arr[2],
		  stringParam4: arr[3],
		  stringParam5: arr[4],
		  stringParam6: arr[5],
          // hospitalId:user.deptPid,
          // deptId:user.deptId,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
          stringParam7: this.createBy,
          stringParam10: this.dateTime[0],
          stringParam11: this.dateTime[1],
          stringParam8: teamsId,
          stringParam9: enertyId,
        },
      });
      console.log(res.data);
      if (res.status) {
        res.data.forEach((v) => {
          this.userList.forEach((user) => {
            if (v.createBy == user.organBusiness.id) {
              v.creator = user.organBusiness.name;
            }
          });
          this.diseasesList.forEach((v2) => {
            if (v.workEnertyType == v2.value) {
              v.disease = v2.name;
            }
          });
          // this.typeList.forEach(type=>{
          //   if(v.workType==type.value){
          //     v.workTypeName=type.name
          //   }
          // })
        });
        this.dataList = res.data;
        this.totalPage = res.totalCount;
      }
      this.dataListLoading = false;
    },
    add() {
      this.$router.push("/zykgl/mission/add");
    },
    goToInfo(row) {
      this.$router.push({ path: "/zykgl/mission/info", query: { id: row.id } });
    },
    async del(row) {
      console.log(row, "row");
      const id = row.id;
      this.$confirm("确定进行[删除宣教]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          // this.dataListSelections.forEach(v=>{
          //   ids.push(v.id)
          // })
          // const id = ids.join(`,`)

          const { data: res } = await this.$httpAes({
            url: this.$http.adornUrl(`/missionaryWork/delete?ids=${id}`),
            method: "post",
				
			data:{
				stringParam1:id
			}
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除宣教成功");
            this.getList();
          }
        })
        .catch(() => {});
    },
    goToEdit(row) {
      this.$router.push({ path: "/zykgl/mission/edit", query: { id: row.id } });
    },
    reset() {
      this.workName = "";
      this.workType = "";
      this.createBy = "";
      this.createBy = "";
      this.dateTime = [];
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '宣教分类',
		
		},
      
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    async getUserList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/organBusiness/backUserList"),
        method: "post",
        data: {
          hospitalId: user.deptPid,
        },
      });
      this.userList = res.data;
    },
    async getDiseasesList() {
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '基础病种',
		
		},
      
      });
      // console.log(res,'res')
      this.diseasesList = res.data;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.mission {
  .el-main {
    padding: 0 20px;
  }
  .search-list {
    display: flex;
    align-items: center;
    .label {
      width: 5%;
    }
  }
}
</style>